import Customer from './components/Customer';
import Decoration from './components/Decoration';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './components/Home';
import Map from './components/Map';
import Products from './components/Products';
import Slide from './components/Slide';
import { MessengerChat } from "react-messenger-chat-plugin";

function App() {
  const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };
  return (
    <div className="App">
      <Header />
      <main className="l-main">
        <Home />
        {/* <Share /> */}
        <Decoration />
        <Products />
        {/* <Accessories /> */}
        {/* <Send /> */}
      <Slide />
        <Customer />
        {/* <Contact /> */}

        <Map />
      </main>
      <Footer />
      <MessengerChat
        pageId="111358264078342"
        language="vi_VN"
        themeColor={"#004a99"}
        bottomSpacing={100}
        loggedInGreeting="Chào bạn! Tôi có thể giúp gì cho bạn"
        loggedOutGreeting="Chào bạn! Tôi có thể giúp gì cho bạn"
        greetingDialogDisplay={"show"}
        debugMode={true}
      />
    </div>
  );
}

export default App;
