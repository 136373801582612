// import slide1 from "../../img/slide1.jpg";
// import slide2 from "../../img/slide2.jpg";
import slide3 from "../../img/slide3.png";
// import slide4 from "../../img/slide4.jpg";
import slide5 from "../../img/slide5.jpg";
import slide6 from "../../img/slide6.jpg";
import slide7 from "../../img/slide7.png";
import slide8 from "../../img/slide8.jpg";
import slide9 from "../../img/slide9.png";
import slide10 from "../../img/slide10.png";
import slide11 from "../../img/slide11.jpg";
import slide12 from "../../img/slide12.png";
import slide13 from "../../img/slide13.png";

export const data = [
  //   slide1,
  //   slide2,
  slide3,
  //   slide4,
  slide5,
  slide6,
  slide7,
  slide8,
  slide9,
  slide10,
  slide11,
  slide12,
  slide13,
];
