import React from "react";

const Footer = () => {
  return (
    <footer className="footer section">
      <div className="footer__container bd-grid bd-container">
        <div className="footer__content">
          <h3 className="footer__title">
            <a
              href="#"
              className="footer__logo"
              style={{ textTransform: "uppercase" }}
            >
              {" "}
              Vinorsoft
            </a>
          </h3>
          <p className="footer__description">
            VINORSOFT là một doanh nghiệp trẻ, năng động, với một đội ngũ chuyên
            gia nhiệt tình, tâm huyết với công việc và có trình độ chuyên môn
            cao.
          </p>
        </div>

        <div className="footer__content">
          <h3 className="footer__title">Liên kết</h3>
          <ul>
            <li>
              <a href="#" className="footer__link">
                Trang chủ
              </a>
            </li>
            <li>
              <a href="#" className="footer__link">
                Về chúng tôi
              </a>
            </li>
            <li>
              <a href="#" className="footer__link">
                Dịch vụ
              </a>
            </li>
            <li>
              <a href="#" className="footer__link">
                Điều khoản dịch vụ
              </a>
            </li>
            <li>
              <a href="#" className="footer__link">
                Chính sách bảo mật
              </a>
            </li>
          </ul>
        </div>

        <div className="footer__content">
          <h3 className="footer__title">Liên hệ</h3>
          <ul>
            <li className="footer__description">
              Địa chỉ ĐKKD: 115/30 đường Lê Quang Định, phường 14, quận Bình Thạnh, TP. Hồ Chí Minh.
            </li>
            <li className="footer__description">Website: <a target="_blank" href="http://vinorsoft.com" className="footer__description-link">vinorsoft.com</a></li>
            <li className="footer__description">Điện thoại: 02877708885</li>
            <li className="footer__description">
              Email: cskh@vinorsoft.com
            </li>
          </ul>
        </div>
        <div className="footer__content">
          <h3 className="footer__title">Đăng ký nhận bản tin</h3>
          <p className="footer__description">
            Hãy đăng ký email để cập nhật những công nghệ và chiến lược
            marketing mới từ chúng tôi!
          </p>
          <form className="footer__form">
            <input></input>
            <button>Subscribe</button>
          </form>
        </div>
      </div>
      <div className="footer__container bd-grid bd-container">
        <div className="footer__content">
          <h3 className="footer__title">Văn phòng</h3>
          <ul>
            <li className="footer__description" style={{ marginLeft: 3 }}>
              1.  Hồ Chí Minh: 491/76 Nguyễn Đình Chiểu, phường 2, quận 3.
            </li>
            <li className="footer__description">
              2. Hà Nội: Tầng 9, Tòa nhà Hoàng Ngọc, số 4 ngõ 82 Dịch Vọng Hậu, phường Dịch Vọng Hậu, quận Cầu Giấy.
            </li>
            <li className="footer__description">
              3. Đà Nẵng: 35 đường Lê Thanh Nghị, phường Hòa Cường Bắc, quận Hải Châu.
            </li>
            <li className="footer__description">
              4. An Giang: 31 đường số 6B, khu dân cư Bắc Hà Hoàng Hổ, phường Mỹ Hòa, Tp. Long Xuyên.
            </li>
          </ul>
        </div>
      </div>
      <p className="footer__copy">&#169; 2021 All right reserve</p>
    </footer>
  );
};

export default Footer;
