import React from 'react'

const Map = () => {
  return (
    <section className='map section bd-container' id='map'>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1327.0248203648146!2d106.68243586863503!3d10.767605358868016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f18ae8f9f65%3A0x2a01190a63e14cf5!2zNDkxLzc2IE5ndXnhu4VuIMSQw6xuaCBDaGnhu4N1LCBQaMaw4budbmcgMiwgUXXhuq1uIDMsIEjhu5MgQ2jDrSBNaW5oLCBWaWV0bmFt!5e0!3m2!1sen!2s!4v1720186984770!5m2!1sen!2s"
        className="map__config"
        loading='lazy'></iframe>
    </section>
  )
}

export default Map